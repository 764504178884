import React, { PureComponent, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CollapseWrapper from '../../../shared/CollapseWrapper.js';
import classnames from 'classnames';

import combineStyles from '../../../../helpers/combineStyles';
import SpacingStyles from '../../../../styles/helpers/spacing';
import LayoutStyles from '../../../../styles/layout';
import ButtonStyles from '../../../../styles/button';
import TypographyStyles from '../../../../styles/typography';
import collapseWrapperStyles from '../../../../styles/components/collapseWrapper';
import Divider from '@material-ui/core/Divider';
import ConfirmDialog from '../../../shared/Modals/ConfirmDialog';
import PrimaryButton from '../../../shared/Buttons/PrimaryButton';
import DocumentItem from './Item';
import { history } from '../../../../helpers/history';

const adblockDetector = require('adblock-detect');

const styles = theme => ({
  activityLogButton: {
    marginBottom: 0,
    marginTop: theme.spacing.unit * 2
  }
});
export class Document extends PureComponent {
  state = {
    dialogAdBlock: false
  };
  componentDidMount() {
    adblockDetector(adBlockDetected => {
      if (adBlockDetected) {
        this.setState({ dialogAdBlock: true });
      }
    });
  }

  _handleCloseDialog = () => {
    this.setState({ dialogAdBlock: false });
  };

  captureVoucher = () => {
    const { salesDetail } = this.props;
    if (salesDetail) {
      history.push(`/digital-sale/capture-voucher/${salesDetail.id}`);
    }
  };

  // eslint-disable-next-line complexity
  render() {
    const {
      classes,
      salesDetail,
      requestPromissoryUrl,
      requestVoucherUrl,
      requestAmortizationUrl,
      requestSecureUrl,
      requestPledgeUrl,
      requestBinUrl,
      requestCardifUrl,
      requestCardifUrlLayerTwoUrl,
      canReattachVoucherOnRemoteSale,
      canGenEnsuranceRequestPDF,
      hasCardifArticleLayerOne,
      hasCardifArticleLayerTwo,
      voluntaryInsuranceLayerTwoName,
      canAttachDocumentsOnTraditionalSale,
      canAttachDocumentsForDocRevisions,
      secureSaleArticleId,
      pledgeArticleId,
    } = this.props;

    const {
      saleType,
      saleStatus,
      missSaleVoucher,
      saleVouchers,
      externalCode,
      saleDetails,
      documentalRevision
    } = salesDetail;

    const isTraditionalSale = saleType === 0;
    const isRemoteSale = saleType === 1;

    const saleHasSecureArticle = saleDetails.some(sd => sd.article.id === secureSaleArticleId);
    const isSecureSale = saleHasSecureArticle && isTraditionalSale;

    const saleHasPledge = saleDetails.some(sd => sd.article.id === pledgeArticleId);
    const isActiveSale = !['Devuelta', 'Anulada'].includes(saleStatus);

    const saleHasVouchers = saleVouchers.length > 0;
    const canAttachVoucherToRemoteSale = missSaleVoucher;

    const canAttachInTraditionalSale = isTraditionalSale &&
      canAttachDocumentsOnTraditionalSale && (
        !saleHasVouchers ||
        (saleHasVouchers && canReattachVoucherOnRemoteSale)
      );

    const canAttachInRemoteSale = isRemoteSale &&
      canAttachVoucherToRemoteSale && (
        !saleHasVouchers ||
        (saleHasVouchers && canReattachVoucherOnRemoteSale)
      );
    const canAttachDocumentsForRevision = canAttachDocumentsForDocRevisions && (!documentalRevision || documentalRevision.status === 2);

    const canAttachVoucher = isActiveSale && externalCode &&
      ((canAttachInTraditionalSale || canAttachInRemoteSale) || canAttachDocumentsForRevision);

    return (
      <CollapseWrapper
        classes={{
          root: classes.root,
          header: classes.panelHeader,
          expandButton: classes.expandButton,
          bodyRoot: classes.collapsePanelBodyRoot
        }}
        header={() => (
          <div className={classes.titleWrapper}>
            <h2 className={classnames(classes.headline8, classes.title)}>
              {'Documentos'}
            </h2>
          </div>
        )}
        body={
          <Fragment>
            <Divider />
            <div className={classes.body}>
              {this.props.canGenInvoicePDF &&
                salesDetail.supplier &&
                salesDetail.supplier.requiresBin && (
                  <DocumentItem
                    name="BIN"
                    url={this.props.binUrl}
                    loading={requestBinUrl}
                  />
                )}
              {this.props.canGenPromissoryPDF && (
                <DocumentItem
                  name="Pagaré"
                  url={this.props.promissoryUrl}
                  loading={requestPromissoryUrl}
                />
              )}
              {this.props.canGenVoucherPDF && (
                <DocumentItem
                  name="Voucher"
                  url={this.props.voucherUrl}
                  loading={requestVoucherUrl}
                />
              )}
              {this.props.canGenAmortizationPDF && (
                <DocumentItem
                  name="Amortización"
                  url={this.props.amortizationUrl}
                  loading={requestAmortizationUrl}
                />
              )}
              {isSecureSale && this.props.canGenSecurePDF && (
                <DocumentItem
                  name="Fianza"
                  url={this.props.secureUrl}
                  loading={requestSecureUrl}
                />
              )}
              {saleHasPledge && this.props.canGeneratePledgePDF && (
                <DocumentItem
                  name="Pignoración"
                  url={this.props.pledgeUrl}
                  loading={requestPledgeUrl}
                />
              )}
              {salesDetail.saleVouchers.length > 0 &&
                salesDetail.saleVouchers.map((item, index) => {
                  return (
                    <DocumentItem
                      key={index}
                      name={`${salesDetail.saleType === 0 ? 'Fact. venta tradicional' : 'Factura de venta'} ${index + 1}`}
                      url={item}
                      loading={false}
                    />
                  );
                })}
              {canGenEnsuranceRequestPDF && hasCardifArticleLayerOne && salesDetail.saleType === 0 && (
                <DocumentItem
                  name={"Seguro cardif"}
                  url={this.props.cardifUrl}
                  loading={requestCardifUrl}
                />
              )}
              {canGenEnsuranceRequestPDF && hasCardifArticleLayerTwo && salesDetail.saleType === 0 && (
                <DocumentItem
                  name={voluntaryInsuranceLayerTwoName}
                  url={this.props.cardifLayerTwoUrl}
                  loading={requestCardifUrlLayerTwoUrl}
                />
              )}
              {canAttachVoucher && (
                <PrimaryButton
                  variant="contained"
                  className={classes.button}
                  disabled={false}
                  onClick={() => this.captureVoucher()}
                >
                  Adjuntar Factura
                </PrimaryButton>
              )
              }
            </div>
            <ConfirmDialog
              open={this.state.dialogAdBlock}
              title="Bloqueador de ventanas emergentes detectado"
              text="Recuerda desactivarlo para poder visualizar los documentos."
              buttonTextConfirm="ok"
              onConfirm={this._handleCloseDialog}
              onClose={this._handleCloseDialog}
            />
          </Fragment>
        }
      />
    );
  }
}

export default withStyles(
  combineStyles(
    SpacingStyles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    styles,
    collapseWrapperStyles
  )
)(Document);

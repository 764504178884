import { FILE_URL_SET, FILE_ERROR_SET } from '../constants/action_constants';
import AxiosRequest from '../services/axios_request';
import { sendRequest, completeRequest } from './request_state';
import { newAlert } from './alert_actions';
import RequestService from '../services/request_service';

const axiosRequest = new AxiosRequest();
const nodeRequest = new RequestService('node');

export function setUrl(fileType, data, documentType = 'pdf') {
  return {
    type: FILE_URL_SET,
    documentType,
    fileType,
    data
  };
}

export function setUrlFromBlob(fileType, blob) {
  return {
    type: FILE_URL_SET,
    fileType,
    blob
  };
}

export function clearUrl(fileType) {
  return {
    type: FILE_ERROR_SET,
    fileType
  };
}

export const pdfVoucher = id => async dispatch => {
  dispatch(sendRequest('VoucherUrl'));
  try {
    dispatch(clearUrl('voucher'));
    const payload = await nodeRequest.post(
      `generate-pdf/voucher/${id}`,
      null,
      null
    );
    dispatch(setUrl('voucher', payload));
  } catch (error) {
    console.error(error);
    dispatch(clearUrl('voucher'));
    dispatch(
      newAlert(
        'error',
        'errors',
        'Error al generar Voucher. Contácte con su administrador.'
      )
    );
  } finally {
    dispatch(completeRequest('VoucherUrl'));
  }
};

export const pdfPromissory = id => async dispatch => {
  dispatch(sendRequest('PromissoryUrl'));
  try {
    dispatch(clearUrl('promissory'));
    const payload = await nodeRequest.post(
      `generate-pdf/promissory`,
      { saleId: id },
      null
    );
    dispatch(setUrl('promissory', payload));
  } catch (error) {
    dispatch(clearUrl('promissory'));
    dispatch(
      newAlert(
        'error',
        'errors',
        'Error al generar Pagaré. Contácte con su administrador.'
      )
    );
  } finally {
    dispatch(completeRequest('PromissoryUrl'));
  }
};

export const pdfBin = id => async dispatch => {
  dispatch(sendRequest('BinUrl'));
  try {
    dispatch(clearUrl('bin'));
    const payload = await nodeRequest.post(
      `generate-pdf/bin/${id}`,
      null,
      null
    );
    dispatch(setUrl('bin', payload));
  } catch (error) {
    dispatch(clearUrl('bin'));
    dispatch(
      newAlert(
        'error',
        'errors',
        'Error al generar BIN. Contácte con su administrador.'
      )
    );
  } finally {
    dispatch(completeRequest('BinUrl'));
  }
};

export const pdfSecure = id => async dispatch => {
  dispatch(sendRequest('SecureUrl'));
  try {
    dispatch(clearUrl('secure'));
    const payload = await nodeRequest.post(
      `generate-pdf/secure/${id}`,
      null,
      null
    );
    dispatch(setUrl('secure', payload));
  } catch (error) {
    dispatch(clearUrl('secure'));
    dispatch(
      newAlert(
        'error',
        'errors',
        'Error al generar FIANZA. Contácte con su administrador.'
      )
    );
  } finally {
    dispatch(completeRequest('SecureUrl'));
  }
};

export const pdfRequestCardif = id => async dispatch => {
  dispatch(sendRequest('CardifUrl'));
  try {
    dispatch(clearUrl('cardif'));
    const payload = await nodeRequest.post(
      `generate-pdf/ensuranceRequest/${id}`,
      { docType: 1 },
      null
    );
    dispatch(setUrl('cardif', payload));
  } catch (error) {
    dispatch(clearUrl('cardif'));
    dispatch(
      newAlert(
        'error',
        'errors',
        'Error al generar solicitud de cardif. Contácte con su administrador.'
      )
    );
  } finally {
    dispatch(completeRequest('CardifUrl'));
  }
};

export const pdfRequestCardifLayerTwo = id => async dispatch => {
  dispatch(sendRequest('CardifUrlLayerTwoUrl'));
  try {
    dispatch(clearUrl('cardifLayerTwo'));
    const payload = await nodeRequest.post(
      `generate-pdf/ensuranceRequest/${id}`,
      { docType: 2 },
      null
    );
    dispatch(setUrl('cardifLayerTwo', payload));
  } catch (error) {
    dispatch(clearUrl('cardifLayerTwo'));
    dispatch(
      newAlert(
        'error',
        'errors',
        'Error al generar solicitud de cardif capa 2. Contácte con su administrador.'
      )
    );
  } finally {
    dispatch(completeRequest('CardifUrlLayerTwoUrl'));
  }
};

export const pdfSatisfactoryDeliveryFormat = () => async dispatch => {
  dispatch(sendRequest('SatisfactoryDeliveryFormatUrl'));
  try {
    dispatch(clearUrl('satisfactoryDeliveryFormat'));
    const payload = await nodeRequest.post(
      `generate-pdf/satisfaction`,
      null,
      null
    );
    dispatch(setUrl('satisfactoryDeliveryFormat', payload));
  } catch (error) {
    dispatch(clearUrl('satisfactoryDeliveryFormat'));
    dispatch(
      newAlert(
        'error',
        'errors',
        'Error al generar el documento de entregado a satisfacción. Contácte con su administrador.'
      )
    );
  } finally {
    dispatch(completeRequest('SatisfactoryDeliveryFormatUrl'));
  }
};

export const pdfAmortization = id => async dispatch => {
  dispatch(sendRequest('AmortizationUrl'));
  try {
    dispatch(clearUrl('amortization'));
    const payload = await nodeRequest.post(
      `generate-pdf/amortization/${id}`,
      null,
      null
    );
    dispatch(setUrl('amortization', payload));
  } catch (error) {
    dispatch(clearUrl('amortization'));
    dispatch(
      newAlert(
        'error',
        'errors',
        'Error al generar el documento de amortización. Venta no existe en OSF.'
      )
    );
  } finally {
    dispatch(completeRequest('AmortizationUrl'));
  }
};

export const pdfPledge = id => async dispatch => {
  dispatch(sendRequest('PledgeUrl'));
  try {
    dispatch(clearUrl('pledge'));
    const payload = await nodeRequest.post(
      `generate-pdf/pledge/${id}`,
      null,
      null
    );
    dispatch(setUrl('pledge', payload));
  } catch (error) {
    dispatch(clearUrl('pledge'));
    dispatch(
      newAlert(
        'error',
        'errors',
        'Error al generar el documento de pignoración. Venta no existe en OSF.'
      )
    );
  } finally {
    dispatch(completeRequest('PledgeUrl'));
  }
};

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SaleDetail from './SaleDetailPage';
import {
  salesDetail,
  approveSale,
  toggleSaleAction
} from '../../../actions/sales_actions';
import {
  pdfPromissory,
  pdfVoucher,
  pdfBin,
  pdfSecure,
  pdfAmortization,
  pdfSatisfactoryDeliveryFormat,
  pdfRequestCardif,
  pdfRequestCardifLayerTwo,
  pdfPledge
} from '../../../actions/file_actions';
import { updateClientInfo } from '../../../actions/client_actions';
import {
  approvePromissory,
  rejectPromissory
} from '../../../actions/promissory_actions';
import { newAlert } from '../../../actions/alert_actions';
import _get from 'lodash/get';
import { fetchSessionData } from '../../../actions/session_actions';

function mapStateToProps(state) {
  const { salesDetail } = state.sales;
  return {
    salesDetail,
    secureSaleArticleId: state.globalSetting.orgData.secure_sale_article_id,
    pledgeArticleId: state.globalSetting.orgData.pledge_article_id,
    isPreapproveSaleWithPledgeActive: state.globalSetting.orgData.is_preapprove_sale_with_pledge_active,
    motorbikeCategoryId: state.globalSetting.orgData.motorbike_category_id,
    motoCategoryId: state.globalSetting.orgData.secure_sale_article_id,
    scoringExtraQuotaId: state.globalSetting.orgData.scoring_extra_quota_id,
    scoringExtraQuotaName: state.globalSetting.orgData.scoring_extra_quota_name,
    canCreateSaleComments:
      state.authentication.currentUser.canCreateSaleComments,
    canListSaleComments: state.authentication.currentUser.canListSaleComments,
    canApproveSale: state.authentication.currentUser.canApproveSale,
    canPreapproveSale: state.authentication.currentUser.canPreapproveSale,
    canApprovePromissory: state.authentication.currentUser.canApprovePromissory,
    canDeliverSale: state.authentication.currentUser.canDeliverSale,
    canRevokeArticlesSales:
      state.authentication.currentUser.canRevokeArticlesSales,
    canRevokeSales:
      state.authentication.currentUser.canRevokeSales,
    canReturnSale: state.authentication.currentUser.canReturnSale,
    canRegisterSinisters: state.authentication.currentUser.canRegisterSinisters,
    canReturnSaleManual: state.authentication.currentUser.canReturnSaleManual,
    canReturnArticlesSaleManual: state.authentication.currentUser.canReturnArticlesSaleManual,
    canViewPromissories: state.authentication.currentUser.canViewPromissories,
    canReattachVoucherOnRemoteSale: state.authentication.currentUser.canReattachVoucherOnRemoteSale,
    canAttachDocumentsOnTraditionalSale: state.authentication.currentUser.canAttachDocumentsOnTraditionalSale,
    canAttachDocumentsForDocRevisions: state.authentication.currentUser.canAttachDocumentsForDocRevisions,
    canReviewQuotaTransfer:
      state.authentication.currentUser.canReviewQuotaTransfer,
    canApproveQuotaTransfer:
      state.authentication.currentUser.canApproveQuotaTransfer,
    canRevokeQuotaTransfer:
      state.authentication.currentUser.canRevokeQuotaTransfer,
    canGenPromissoryPDF: state.authentication.currentUser.canGenPromissoryPDF,
    canGenInvoicePDF: state.authentication.currentUser.canGenInvoicePDF,
    canGenVoucherPDF: state.authentication.currentUser.canGenVoucherPDF,
    canGenSecurePDF: state.authentication.currentUser.canGenSecurePDF,
    canGeneratePledgePDF: state.authentication.currentUser.canGeneratePledgePDF,
    canGenEnsuranceRequestPDF: state.authentication.currentUser.canGenEnsuranceRequestPDF,
    canGenAmortizationPDF:
      state.authentication.currentUser.canGenAmortizationPDF,
    permissions: state.authentication.permissions,
    requestSend: state.requestState.requestSend,
    requestPromissoryUrl: state.requestState[`requestPromissoryUrlSend`],
    requestVoucherUrl: state.requestState[`requestVoucherUrlSend`],
    requestBinUrl: state.requestState[`requestBinUrlSend`],
    requestSecureUrl: state.requestState[`requestSecureUrlSend`],
    requestPledgeUrl: state.requestState[`requestPledgeUrlSend`],
    requestSatisfactoryDeliveryFormatUrl:
      state.requestState[`requestSatisfactoryDeliveryFormatUrlSend`],
    requestAmortizationUrl: state.requestState[`requestAmortizationUrlSend`],
    requestApprovePromissorySend:
      state.requestState[`requestApprovePromissorySend`],
    requestRejectPromissorySend:
      state.requestState[`requestRejectPromissorySend`],
    voucherUrl: state.file.voucherUrl,
    amortizationUrl: state.file.amortizationUrl,
    promissoryUrl: state.file.promissoryUrl,
    secureUrl: state.file.secureUrl,
    pledgeUrl: state.file.pledgeUrl,
    binUrl: state.file.binUrl,
    solidaryDebtorCauses: state.globalSetting.orgData.solidary_debtor_causes,
    daysForSinister: state.globalSetting.orgData.days_for_sinister,
    companyName: state.globalSetting.setting,
    satisfactoryDeliveryFormatUrl: state.file.satisfactoryDeliveryFormatUrl,
    companyCode: state.sessionData.companyName,
    rejectPromissoryCausals: state.globalSetting.orgData.osf_reject_causes,
    cardifArticleLayerOne: _get(
      state,
      'globalSetting.orgData.voluntary_insurance.cardif.article'
    ),
    cardifUrl: state.file.cardifUrl,
    requestCardifUrl: state.requestState[`requestCardifUrlSend`],
    cardifLayerTwoUrl: state.file.cardifLayerTwoUrl,
    requestCardifUrlLayerTwoUrl: state.requestState[`requestCardifUrlLayerTwoUrlSend`],
    voluntaryInsuranceLayerTwoArticleId: state.globalSetting.orgData.voluntary_insurance_layer_two_article_id,
    voluntaryInsuranceLayerTwoName: state.globalSetting.orgData.voluntary_insurance_layer_two_name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    details: bindActionCreators(salesDetail, dispatch),
    approve: bindActionCreators(approveSale, dispatch),
    clientUpdate: bindActionCreators(updateClientInfo, dispatch),
    approvePromissory: bindActionCreators(approvePromissory, dispatch),
    rejectPromissory: bindActionCreators(rejectPromissory, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    toggleSaleAction: bindActionCreators(toggleSaleAction, dispatch),
    pdfVoucher: bindActionCreators(pdfVoucher, dispatch),
    pdfPromissory: bindActionCreators(pdfPromissory, dispatch),
    pdfSecure: bindActionCreators(pdfSecure, dispatch),
    pdfPledge: bindActionCreators(pdfPledge, dispatch),
    pdfAmortization: bindActionCreators(pdfAmortization, dispatch),
    pdfBin: bindActionCreators(pdfBin, dispatch),
    pdfSatisfactoryDeliveryFormat: bindActionCreators(
      pdfSatisfactoryDeliveryFormat,
      dispatch
    ),
    fetchSessionData: bindActionCreators(fetchSessionData, dispatch),
    pdfRequestCardif: bindActionCreators(pdfRequestCardif, dispatch),
    pdfRequestCardifLayerTwo: bindActionCreators(pdfRequestCardifLayerTwo, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaleDetail);

import _pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

const saleFilters = filters => {
  const {
    sale,
    supplier,
    pointOfSale,
    status,
    isCardif,
    isSecureSale,
    hasSupportDocs,
    contract,
    registered_at,
    saleType,
    page,
    per_page
  } = filters;
  const registered_at_range = registered_at ? registered_at.split(' - ') : [];
  const sanitizedFilters = {
    idEq: sale,
    contractIdEq: contract,
    pointOfSaleSupplierNameCont: supplier,
    pointOfSaleNameCont: pointOfSale,
    saleTypeEq: saleType,
    page: page,
    perPage: per_page,
  };
  if (registered_at_range.length > 1) {
    sanitizedFilters.registeredAtGteq = moment(
      registered_at_range[0]
    ).format();
    sanitizedFilters.registeredAtLteq = moment(registered_at_range[1])
      .endOf('day')
      .format();
  } else if (registered_at_range.length > 0) {
    sanitizedFilters.registeredAtGteq = moment(
      registered_at_range[0]
    ).format();
    sanitizedFilters.registeredAtLteq = moment(registered_at_range[0])
      .endOf('day')
      .format();
  }

  if (status === 'preaprobada') {
    sanitizedFilters.deliveredAtNull = 1;
    sanitizedFilters.approvedAtNull = 1;
    sanitizedFilters.canceledAtNull = 1;
    sanitizedFilters.returnedAtNull = 1;
    sanitizedFilters.preApprovedAtNotNull = 1;
  }

  if (status === 'pendienteEntrega') {
    sanitizedFilters.deliveredAtNull = 1;
    sanitizedFilters.approvedAtNotNull = 1;
    sanitizedFilters.canceledAtNull = 1;
    sanitizedFilters.returnedAtNull = 1;
  }

  if (status === 'pendienteRevision') {
    sanitizedFilters.deliveredAtNotNull = 1;
    sanitizedFilters.approvedAtNull = 1;
    sanitizedFilters.canceledAtNull = 1;
    sanitizedFilters.returnedAtNull = 1;
  }

  if (status === 'registrada') {
    sanitizedFilters.approvedAtBlank = 1;
    sanitizedFilters.deliveredAtNull = 1;
    sanitizedFilters.canceledAtNull = 1;
    sanitizedFilters.returnedAtNull = 1;
    sanitizedFilters.preApprovedAtNull = 1;
  }

  if (status === 'anulada') {
    sanitizedFilters.canceledAtNotNull = 1;
  }

  if (status === 'entregada') {
    sanitizedFilters.deliveredAtNotNull = 1;
    sanitizedFilters.approvedAtNotNull = 1;
    sanitizedFilters.canceledAtNull = 1;
    sanitizedFilters.returnedAtNull = 1;
  }

  if (status === 'devuelta') {
    sanitizedFilters.returnedAtNotNull = 1;
  }

  if (isCardif === 'cardif') {
    sanitizedFilters.hasVoluntaryInsuranceEq = 'true';
  }

  if (isCardif === 'regular') {
    sanitizedFilters.hasVoluntaryInsuranceEq = 'false';
  }

  if (isSecureSale === 'secure') {
    sanitizedFilters.isSecureSale = 'true';
  }

  if (isSecureSale === 'regular') {
    sanitizedFilters.isSecureSale = 'false';
  }

  if (hasSupportDocs === 'docs') {
    sanitizedFilters.hasSupportDocs = 'true';
  }

  if (hasSupportDocs === 'regular') {
    sanitizedFilters.hasSupportDocs = 'false';
  }

  return _pickBy(sanitizedFilters, filter => !!filter);
};

export default saleFilters;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Table from '../../shared/Table/Table';
import { columns, columnsPOS, mapData } from './TableMetadata';

import parseQuerystring from '../../../helpers/parseQuerystring';
import SalesFilter from './SaleFilter';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import Header from '../DashboardSellers/Header';

import ListsStyle from '../__styles__/Lists.style';

import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class SalesList extends React.PureComponent {
  state = {
    isSeller: this.props.canListDashboardSellersSummaries,
    isFreelance: this.props.canViewSummary,
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    rowsSelected: []
  };

  componentDidMount() {
    this._fetchItems();
    if (!this.props.suppliers && !this.props.requestOrgDataSend) {
      this.props.fetchOrgData();
    }
    if (!this.props.requestPointOfSalesSend) {

      const filterBy = this.props.canFilterSalesBy;
      if (filterBy != 'none' && filterBy != 'user_point_of_sales') {
        const posParams = {};
        if (filterBy == 'supplier_point_of_sales') {
          posParams.supplier_id_eq = this.props.currentUserSupplier.id;
        }
        this.props.fetchPointsOfSaleNames(posParams);
      }
    }
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchSales({
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _isOnTemporalWindow = params => {
    const query = params || parseQuerystring(this.props) || {};
    if (query && query.registered_at) {
      const split = query.registered_at.split(' - ');
      const registered_at_init = split[0];
      let registered_at_end = registered_at_init;
      if (split[1]) {
        registered_at_end = split[1];
      }
      const dateDiff =
        new Date(registered_at_end) - new Date(registered_at_init);
      const dateDiffInMonths = dateDiff / 1000 / 3600 / 24 / 31;
      //Permit download when time difference is 1 month or less
      if (dateDiffInMonths < 1) {
        return true;
      }
    }

    if (
      query &&
      (query.status == 'pendienteRevision' ||
        query.status == 'pendienteEntrega' ||
        query.type == 'cardif' ||
        query.type == 'regular')
    ) {
      return true;
    }
    return false;
  };

  _handleChangeFilter = params => {
    this._fetchItems(params);
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };
  _handleRowClick = id => this.props.history.push(`/sales/${id}`);

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  _handleSelectRow = rowsSelected => {
    this.setState({ rowsSelected });
  };

  _getMenuOptions = sales => {
    const { rowsSelected } = this.state;
    const menuOptions = [];
    if (!sales || rowsSelected.length < 1) return menuOptions;
    const delivered = [];
    const canceled = [];
    rowsSelected.forEach(r => {
      const s = sales[r];
      if (s && s.deliveredAt) delivered.push(r);
      if (s && s.canceledAt) canceled.push(r);
    });

    if (delivered.length == 0 && canceled.length == 0) {
      menuOptions.push({
        label: 'Entregar',
        action: this._productDelivery
      });
    }
    if (canceled.length == 0 && delivered.length == 0) {
      menuOptions.push({
        label: 'Anular',
        action: this._cancelSale
      });
    }
    return menuOptions;
  };

  _productDelivery = () => {
    this.props.toggleAction('Product-Delivery', true);
  };

  _cancelSale = () => {
    this.props.toggleAction('Cancel-Sale', true);
  };

  _batchedSales = () => {
    const { sales } = this.props;
    const { rowsSelected } = this.state;
    const batched = [];
    rowsSelected.forEach(e => {
      batched.push(sales[e]);
    });
    return batched;
  };

  excelReportDownloadButtonIsVisible = () => {
    return this.props.canExportSalesToExcel && this._isOnTemporalWindow();
  };

  fetchIndexExcelReportFile = () => {
    const query = parseQuerystring(this.props) || {};
    this.props.exportToXlsx({
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  render() {
    const {
      sales = [],
      meta = {},
      suppliers,
      pointsOfSales,
      currentUserPos,
      canFilterSalesBy,
      classes,
      requestSend,
      dataSellersSummary,
      requestDashboardSellersSummarySend,
      requestXlsxUrl
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};
    let pos = pointsOfSales;
    if (currentUserPos.length > 0 && canFilterSalesBy != 'supplier_point_of_sales') {
      pos = currentUserPos;
    }
    return (
      <div>
        <div>
          {this.state.isSeller && (
            <Header
              role={this.state.isFreelance}
              data={dataSellersSummary}
              isLoading={requestDashboardSellersSummarySend}
            />
          )}
        </div>

        {!this.state.isSeller && (
          <div className={classes.titleRootWrapper}>
            <h1 className={classnames(classes.headline4, classes.title)}>
              {'Ventas'}
            </h1>
          </div>
        )}
        <div>
          <div className={classes.filterOptionsWrapper}>
            <SalesFilter
              filterSalesBy={canFilterSalesBy}
              suppliers={suppliers}
              pointsOfSales={pos}
              requestPointOfSalesSend={this.props.requestPointOfSalesSend}
              onChangeFilter={this._handleChangeFilter}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Venta'}
            columns={canFilterSalesBy == 'all' ? columns : columnsPOS}
            data={mapData(sales)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props => this._handleRowClick(props.id)}
            totalCount={sales.length}
            loading={requestSend}
            withSelection={false} // TODO: BATCH-ACTIONS SALE
            rowsSelected={this.state.rowsSelected}
            onSelectRow={this._handleSelectRow}
            withDownload={this.excelReportDownloadButtonIsVisible()}
            onDownloadIconClick={this.fetchIndexExcelReportFile}
            loadingDownload={requestXlsxUrl}
            withNoCountPagination={true}
            nextPage={meta.nextPage}
          // batchItemsOptions={this._getMenuOptions(sales)}
          />
        </div>
      </div>
    );
  }
}

SalesList.propTypes = {
  classes: PropTypes.object.isRequired,
  sales: PropTypes.array.isRequired,
  fetchSales: PropTypes.func.isRequired,
  meta: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const styles = () => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(SalesList);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, withStyles } from '@material-ui/core';
import CustomDialog from '../../shared/Modals/CustomDialog';

class ApproveSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: ''
    };
  }

  _handleSave = () => {
    const { sale, openPre } = this.props;
    const { comment } = this.state;
    if (!sale) return;

    if (openPre) {
      this.props.preApproveSale(sale.id, comment);
    } else {
      this.props.approveSale(sale.id, comment);
    }
  };

  _handleClose = () => {
    if (!this.props.requestSend) {
      this.props.toggleSaleAction('Approve-Sale', false);
      this.props.toggleSaleAction('PreApprove-Sale', false);
      this.setState({ comment: '' });
    }
  };

  _handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { open = false, openPre, requestSend } = this.props;
    const { comment } = this.state;
    const approveText = openPre ? 'Preaprobación' : 'Aprobación';

    return (
      <div>
        <CustomDialog
          open={open || openPre}
          loading={requestSend}
          disabled={requestSend}
          onClose={this._handleClose}
          onConfirm={this._handleSave}
          title={`${approveText} de venta`}
          contentText={`Puede añadir un comentario a esta venta, será visible desde Sistema Brilla.`}
          buttonText={openPre ? 'PREAPROBAR': 'APROBAR'}
        >
          <Grid container>
            <Grid item xs={12}>
              <TextField
                label="Comentario"
                name="comment"
                margin="normal"
                variant="outlined"
                onChange={this._handleChange}
                value={comment}
                disabled={requestSend}
                fullWidth
              />
            </Grid>
          </Grid>
        </CustomDialog>
      </div>
    );
  }
}

const styles = (/* theme */) => ({});

ApproveSale.propTypes = {
  open: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(ApproveSale);

export const STATUSES = {
  REGISTERED: 'Registrada',
  PENDING_REVISION: 'Pendiente_Revision',
  PENDING_DELIVERY: 'Pendiente_Entrega',
  PREAPPROVED: 'Pre-aprobada',
  APPROVED: 'Aprobada',
  DELIVERED: 'Entregada',
  EXCEPTION: 'Sin estado - Revise con un administrador',
  REVOKED: 'Anulada',
  REJECTED: 'Rechazado',
  CANCELED: 'Anulado',
  PARTIALLY_REVOKED: 'Anulación parcial',
  PARTIALLY_RETURNED: 'Devolución parcial',
  PARTIALLY_RETURNED_MANUAL: 'Devolución manual parcial',
  RETURNED_MANUAL: 'Devolución manual',
  RETURNED: 'Devuelta',
  REVIEWED: 'Revisada'
};

export const MOVEMENT_STATUSES = {
  PARTIALLY_REVOKED: 'partially_revoked',
  PARTIALLY_RETURNED: 'partially_returned',
  RETURNED: 'returned',
  PARTIALLY_RETURNED_MANUAL: 'partially_returned_manual',
  RETURNED_MANUAL: 'returned_manual'
};

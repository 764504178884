import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { newAlert } from '../../../actions/alert_actions';
import PortalEndSalePage from './PortalEndSalePage';
import { setNextStep } from '../../../actions/portal_financing_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';
import {
  pdfVoucher,
  pdfBin,
  pdfPromissory,
  pdfAmortization,
  pdfSecure,
  pdfSatisfactoryDeliveryFormat,
  pdfRequestCardif,
  pdfPledge,
  pdfRequestCardifLayerTwo
} from '../../../actions/file_actions';
import _get from 'lodash/get';
import { fetchSessionData } from '../../../actions/session_actions';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  return {
    routes: state.stepper.routes,
    process: state.stepper.process,
    portalNext: state.stepper.portalNext,
    promissoryNext: state.stepper.promissoryNext,
    serverErrors: pf.errors,
    saleNumber: pf.saleNumber,
    isSecureSale: pf.isSecureSale,
    canGenPromissoryPDF: state.authentication.currentUser.canGenPromissoryPDF,
    canGenInvoicePDF: state.authentication.currentUser.canGenInvoicePDF,
    canGenVoucherPDF: state.authentication.currentUser.canGenVoucherPDF,
    canGenSecurePDF: state.authentication.currentUser.canGenSecurePDF,
    canGenEnsuranceRequestPDF: state.authentication.currentUser.canGenEnsuranceRequestPDF,
    canGenInformationLetterPDF:
      state.authentication.currentUser.canGenInformationLetterPDF,
    canGenAmortizationPDF:
      state.authentication.currentUser.canGenAmortizationPDF,
    canGeneratePledgePDF: state.authentication.currentUser.canGeneratePledgePDF,
    permissions: state.authentication.permissions,
    requestSend: state.requestState.requestSend,
    requestPromissoryUrl: state.requestState[`requestPromissoryUrlSend`],
    requestVoucherUrl: state.requestState[`requestVoucherUrlSend`],
    requestBinUrl: state.requestState[`requestBinUrlSend`],
    requestSecureUrl: state.requestState[`requestSecureUrlSend`],
    requestSatisfactoryDeliveryFormatUrl:
      state.requestState[`requestSatisfactoryDeliveryFormatUrlSend`],
    requestAmortizationUrl: state.requestState[`requestAmortizationUrl`],
    voucherUrl: state.file.voucherUrl,
    promissoryUrl: state.file.promissoryUrl,
    secureUrl: state.file.secureUrl,
    binUrl: state.file.binUrl,
    amortizationUrl: state.file.amortizationUrl,
    showBin: state.portalFinancing.portalPOS[0].attributes.supplier.requiresBin,
    satisfactoryDeliveryFormatUrl: state.file.satisfactoryDeliveryFormatUrl,
    companyCode: state.sessionData.companyName,
    articles: pf.selectedArticles,
    cardifArticleLayerOne: _get(
      state,
      'globalSetting.orgData.voluntary_insurance.cardif.article'
    ),
    cardifUrl: state.file.cardifUrl,
    requestCardifUrl: state.requestState[`requestCardifUrlSend`],
    cardifLayerTwoUrl: state.file.cardifLayerTwoUrl,
    pledgeUrl: state.file.pledgeUrl,
    requestCardifUrlLayerTwoUrl: state.requestState[`requestCardifUrlLayerTwoUrlSend`],
    requestPledgeUrl: state.requestState[`requestPledgeUrlSend`],
    voluntaryInsuranceLayerTwoArticleId: state.globalSetting.orgData.voluntary_insurance_layer_two_article_id,
    pledgeArticleId: state.globalSetting.orgData.pledge_article_id,
    voluntaryInsuranceLayerTwoName: state.globalSetting.orgData.voluntary_insurance_layer_two_name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    pdfVoucher: bindActionCreators(pdfVoucher, dispatch),
    pdfPromissory: bindActionCreators(pdfPromissory, dispatch),
    pdfBin: bindActionCreators(pdfBin, dispatch),
    pdfSecure: bindActionCreators(pdfSecure, dispatch),
    pdfAmortization: bindActionCreators(pdfAmortization, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    pdfSatisfactoryDeliveryFormat: bindActionCreators(
      pdfSatisfactoryDeliveryFormat,
      dispatch
    ),
    pdfSatisfactoryDeliveryFormat: bindActionCreators(
      pdfSatisfactoryDeliveryFormat,
      dispatch
    ),
    fetchSessionData: bindActionCreators(fetchSessionData, dispatch),
    pdfRequestCardif: bindActionCreators(pdfRequestCardif, dispatch),
    pdfRequestCardifLayerTwo: bindActionCreators(pdfRequestCardifLayerTwo, dispatch),
    pdfPledge: bindActionCreators(pdfPledge, dispatch),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PortalEndSalePage);

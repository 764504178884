import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import parseQuerystring from '../../../helpers/parseQuerystring';

import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import TextFieldInput from '../../shared/Inputs/TextField';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import PickCustomDateModal from '../../shared/Inputs/PickCustomDateModal';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

import _debounce from 'lodash/debounce';
import _find from 'lodash/find';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import _replace from 'lodash/replace';

import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';

import {
  statusOptions,
  originalDateOptions,
  dateOptions,
  saleTypeOptions,
  cardifOptions,
  secureSaleOptions,
  docsSupportOptions
} from './dropDownOptions';

import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';

import { CUSTOM_DATE } from './constants';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';

class SaleFilter extends PureComponent {
  state = {
    sale: '',
    supplier: '',
    pointOfSale: '',
    openPickCustomDate: null,
    status: 'todas',
    type: 'todas',
    isCardif: 'todas',
    isSecureSale: 'todas',
    hasSupportDocs: 'todas',
    contract: '',
    saleType: 'todas',
    registered_at: 'todas',
    customDate: CUSTOM_DATE
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    const custom = {};
    if (query && query.registered_at) {
      const split = query.registered_at.split(' - ');
      if (split[1]) custom.customDate = query.registered_at;
    }
    this.setState({
      ...this.state,
      ...query,
      ...custom
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleAutoSuggest = name => (event, { newValue }) => {
    this.setState({ [name]: newValue }, (name, newValue) =>
      this._searchCallback(name, newValue)
    );
  };

  _handleDateFilter = ({ target: { name, value } }) => {
    if (value === this.state.customDate) {
      this.setState({ openPickCustomDate: name });
    } else {
      this.setState(
        { [name]: value, customDate: CUSTOM_DATE },
        this._handleChangeFilter
      );
    }
  };

  _handleDateRangeFilter = ({ target: { name, value } }) => {
    const customDate = !_find(
      originalDateOptions,
      availableOption => availableOption.value === value
    )
      ? value
      : CUSTOM_DATE;
    this.setState(
      { [name]: value, openPickCustomDate: null, customDate },
      this._handleChangeFilter
    );
  };

  _handleClosePickCustomDate = () =>
    this.setState({ openPickCustomDate: null });

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter && filter != 'todas'),
      [
        'sale',
        'supplier',
        'pointOfSale',
        'status',
        'contract',
        'saleType',
        'isCardif',
        'isSecureSale',
        'hasSupportDocs',
        'registered_at'
      ]
    );
    return filterParams;
  };

  _getSupplierSuggestions = async value => {
    const suppliers = this.props.suppliers.map(supplier => ({
      label: supplier.name,
      value: supplier.id
    }));

    const suggestions = filterListFuzzyly(value, suppliers, 'label');

    return Promise.resolve(suggestions);
  };

  _getPosSuggestions = async value => {
    const pointsOfSales = this.props.pointsOfSales.map(pointOfSale => ({
      label: `${_replace(pointOfSale.name, 'FNB - ', '')}`,
      value: pointOfSale.id
    }));
    const suggestions = filterListFuzzyly(value, pointsOfSales, 'label');

    return Promise.resolve(suggestions);
  };

  render() {
    const { classes } = this.props;
    const filterBy = this.props.filterSalesBy;

    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="sale"
                  name="sale"
                  value={this.state.sale}
                  onChange={this._handleChange}
                  label="# Venta"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <OutlinedSelectChippedInput
                  label={'Fecha'}
                  name={'registered_at'}
                  options={dateOptions(
                    this.state.customDate,
                    this.state.registered_at
                  )}
                  value={this.state.registered_at}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  inputLabel={'Delivery Date'}
                  onChange={this._handleDateFilter}
                  withChip={false}
                />
              </Grid>
              {filterBy == 'all' && (
                <Grid item xs={6} sm={2}>
                  <AutocompleteInput
                    id="name"
                    name="supplier"
                    label="Aliado"
                    margin="normal"
                    variant="outlined"
                    error={''}
                    helperText={''}
                    value={this.state.supplier}
                    onChange={change => {
                      if (this.state.supplier && !change) {
                        this._handleSelectChange({
                          target: { name: 'supplier', value: '' }
                        });
                      }
                      this.setState({ supplier: change });
                    }}
                    suggestions={this.props.suppliers}
                    getSuggestions={this._getSupplierSuggestions}
                    onSuggestionSelected={supplier =>
                      this._handleSelectChange({
                        target: { name: 'supplier', value: supplier.label }
                      })
                    }
                  />
                </Grid>
              )}
              {(filterBy == 'user_point_of_sales' ||
                filterBy == 'supplier_point_of_sales') && (
                  <Grid item xs={6} sm={2}>
                    <AutocompleteInput
                      name="pointOfSale"
                      label="Punto de venta"
                      margin="normal"
                      variant="outlined"
                      error={''}
                      helperText={''}
                      value={this.state.pointOfSale}
                      onChange={change => {
                        if (this.state.pointOfSale && !change) {
                          this._handleSelectChange({
                            target: { name: 'pointOfSale', value: '' }
                          });
                        }
                        this.setState({ pointOfSale: change });
                      }}
                      suggestions={this.props.pointsOfSales}
                      getSuggestions={this._getPosSuggestions}
                      onSuggestionSelected={pointOfSale =>
                        this._handleSelectChange({
                          target: {
                            name: 'pointOfSale',
                            value: pointOfSale.label
                          }
                        })
                      }
                    />
                  </Grid>
                )}
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="contract"
                  name="contract"
                  value={this.state.contract}
                  onChange={this._handleChange}
                  label="# Contrato"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <OutlinedSelectChippedInput
                  label={'Estado'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'status'}
                  options={statusOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'Status'}
                  value={this.state.status}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <OutlinedSelectChippedInput
                  label={'Tipo'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'saleType'}
                  options={saleTypeOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'saleType'}
                  value={this.state.saleType}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <OutlinedSelectChippedInput
                  label={'¿Cardif?'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'isCardif'}
                  options={cardifOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'isCardif'}
                  value={this.state.isCardif}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <OutlinedSelectChippedInput
                  label={'Con fianza'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'isSecureSale'}
                  options={secureSaleOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'isSecureSale'}
                  value={this.state.isSecureSale}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <OutlinedSelectChippedInput
                  label={'Con doc soporte'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'hasSupportDocs'}
                  options={docsSupportOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'hasSupportDocs'}
                  value={this.state.hasSupportDocs}
                  withChip={false}
                />
              </Grid>
            </Grid>
            {!!this.state.openPickCustomDate && (
              <PickCustomDateModal
                name={this.state.openPickCustomDate}
                onClose={this._handleClosePickCustomDate}
                onSave={this._handleDateRangeFilter}
                value={this.state.registered_at}
              />
            )}
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({
  root: {
    // marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA'
  },
  actionBar: {
    marginTop: theme.spacing.unit * 2.2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});
SaleFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(SaleFilter);

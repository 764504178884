import moment from 'moment-timezone';
import { CUSTOM_DATE } from './constants';

export const statusOptions = [
  { value: 'todas', title: 'Todas' },
  { value: 'anulada', title: 'Anulada' },
  { value: 'preaprobada', title: 'Pre-aprobada' },
  { value: 'pendienteEntrega', title: 'Pendiente Entrega' },
  { value: 'pendienteRevision', title: 'Pendiente Revisión' },
  { value: 'devuelta', title: 'Devuelta' },
  { value: 'entregada', title: 'Entregada' },
  { value: 'registrada', title: 'Registrada' }
];

export const cardifOptions = [
  { value: 'todas', title: 'Todas' },
  { value: 'cardif', title: 'SI' },
  { value: 'regular', title: 'NO' }
];

export const secureSaleOptions = [
  { value: 'todas', title: 'Todas' },
  { value: 'secure', title: 'SI' },
  { value: 'regular', title: 'NO' }
];

export const docsSupportOptions = [
  { value: 'todas', title: 'Todas' },
  { value: 'docs', title: 'SI' },
  { value: 'regular', title: 'NO' }
];

export const originalDateOptions = [
  { value: 'todas', title: 'Todas' },
  { value: moment().format('YYYY-MM-DD'), title: 'Hoy' },
  {
    value: `${moment()
      .startOf('week')
      .format('YYYY-MM-DD')} - ${moment()
        .endOf('week')
        .format('YYYY-MM-DD')}`,
    title: 'Esta semana'
  },
  {
    value: `${moment()
      .startOf('month')
      .format('YYYY-MM-DD')} - ${moment()
        .endOf('month')
        .format('YYYY-MM-DD')}`,
    title: 'Este mes'
  }
];

export const dateOptions = (customDate, registered_at) => [
  ...originalDateOptions,
  {
    value: customDate !== CUSTOM_DATE ? registered_at : CUSTOM_DATE,
    title: customDate !== CUSTOM_DATE ? registered_at : 'Personalizada'
  }
];

export const saleTypeOptions = [
  { value: 'todas', title: 'Todas' },
  { value: '0', title: 'Tradicional' },
  { value: '1', title: 'Venta Desmaterializada' },
  { value: '2', title: 'Venta Digital' }
];

import { SET_CURRENT_USER, SET_COURSE_DETAIL } from '../constants/action_constants';

import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';

const initialState = {
  loggedIn: null,
  currentUser: null,
  permissions: [],
  mobileConfirmed: null
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      sessionStorage.setItem('user', JSON.stringify(action.data));
      window.name = "*ukn*";
      const all_sales_permission =
        _findIndex(
          action.data.permissions,
          p => p.action == 'list_all_sales'
        ) >= 0;
      const fnb_coordinator_role =
        _findIndex(
          action.data.roles, r => r.name == 'coordinador_aliado_fnb'
        ) >= 0;

      const pos = _get(action, 'data.pointOfSales.length', 0);
      let filterSalesBy = 'none';
      if (all_sales_permission) {
        filterSalesBy = 'all';
      } else if (pos > 0 && !fnb_coordinator_role) {
        filterSalesBy = 'user_point_of_sales';
      } else if (action.data.supplier) {
        filterSalesBy = 'supplier_point_of_sales';
      }

      return {
        ...state,
        loggedIn: true,
        loading: false,
        mobileConfirmed: action.data.mobileConfirmedAt,
        currentUser: {
          ...action.data,
          canFilterSalesBy: filterSalesBy,
          canUpdateHasBiometricReaderField:
            _findIndex(
              action.data.permissions,
              p => p.action === 'edit_user_has_biometric_reader_field'
            ) >= 0,
          canUpdateTheReceiptOfSchedulingNotifications:
            _findIndex(
              action.data.permissions,
              p =>
                p.action ===
                'edit_the_receipt_of_scheduling_notifications'
            ) >= 0,
          canUpdateSupplier:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_suppliers'
            ) >= 0,
          canViewCorporations:
            _findIndex(
              action.data.permissions,
              p => p.action === 'view_corporations'
            ) >= 0,
          canViewPOS:
            _findIndex(
              action.data.permissions,
              p => p.action === 'view_point_of_sales'
            ) >= 0,
          canUpdateGenericField:
            _findIndex(
              action.data.permissions,
              p => p.action === 'edit_user_generic_field'
            ) >= 0,
          canUpdateUserMedia:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_user_media'
            ) >= 0,
          canUpdateUserPassword:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_user_password'
            ) >= 0,
          canResendConfirmationEmail:
            _findIndex(
              action.data.permissions,
              p => p.action === 'resend_confirmation_email'
            ) >= 0,
          canModifyUserSupplier:
            _findIndex(
              action.data.permissions,
              p => p.action === 'modify_user_supplier'
            ) >= 0,
          canUpdateBasicPersonalData:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_basic_personal_data'
            ) >= 0,
          canConfirmUserMedia:
            _findIndex(
              action.data.permissions,
              p => p.action === 'confirm_user_media_updating'
            ) >= 0,
          canUpdateRoles:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_roles_users'
            ) >= 0,
          canListSalesmanRoles:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_salesman_roles'
            ) >= 0,
          canUpdatePictureProfile:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_user_picture_profile'
            ) >= 0,
          canUpdateAnotherUserPictureProfile:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_another_user_picture_profile'
            ) >= 0,
          canUpdateUserPersonalData:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_user_personal_data_handling'
            ) >= 0,
          canUpdateAnotherUserPersonalData:
            _findIndex(
              action.data.permissions,
              p =>
                p.action === 'update_another_user_personal_data_handling'
            ) >= 0,
          canUpdateAdditionalInfo:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_user_additional_info'
            ) >= 0,
          canUpdateAnotherUserAdditionalInfo:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_another_user_additional_info'
            ) >= 0,
          canUnblockUsers:
            _findIndex(
              action.data.permissions,
              p => p.action === 'unblock_users'
            ) >= 0,
          canUpdateUsers:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_users'
            ) >= 0,
          canValidateUsers:
            _findIndex(
              action.data.permissions,
              p => p.action === 'validate_users'
            ) >= 0,
          canCreateUsers:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_users'
            ) >= 0,
          canCreateSellers:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_sellers'
            ) >= 0,
          canCreateNonAdminUsers:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_non_admin_users'
            ) >= 0,
          canCreateSellerAsHugeSurfaceCoordinator:
            _findIndex(
              action.data.permissions,
              p =>
                p.action === 'create_seller_as_huge_surface_coordinator'
            ) >= 0,
          canUpdateUserPoS:
            _findIndex(
              action.data.permissions,
              p => p.action === 'association_request'
            ) >= 0,
          canCreateSaleComments:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_comments_to_sales'
            ) >= 0,
          canListSaleComments:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_comments_to_sales'
            ) >= 0,
          canListAllSales: all_sales_permission,
          canApproveSale:
            _findIndex(
              action.data.permissions,
              p => p.action === 'approve_sale'
            ) >= 0,
          canUpdateUniquePromissory:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_unique_promissories'
            ) >= 0,
          canUpdateUnmaterializedPromissory:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_unmaterialized_promissories'
            ) >= 0,
          canUpdateNoUniquePromissory:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_no_unique_promissories'
            ) >= 0,
          canCancelPromissory:
            _findIndex(
              action.data.permissions,
              p => p.action === 'revoke_promissories'
            ) >= 0,
          canCancelUnmaterializedPromissory:
            _findIndex(
              action.data.permissions,
              p => p.action === 'cancel_unmaterialized_promissory'
            ) >= 0,
          canApprovePromissory:
            _findIndex(
              action.data.permissions,
              p => p.action === 'approve_promissory'
            ) >= 0,
          canDeliverSale:
            _findIndex(
              action.data.permissions,
              p => p.action === 'delivery_sales'
            ) >= 0,
          canReturnSale:
            _findIndex(
              action.data.permissions,
              p => p.action === 'return_sales'
            ) >= 0,
          canReturnSaleManual:
            _findIndex(
              action.data.permissions,
              p => p.action === 'return_sales_manual'
            ) >= 0,
          canReturnArticlesSaleManual:
            _findIndex(
              action.data.permissions,
              p => p.action === 'return_articles_sales_manual'
            ) >= 0,
          canReviewReturnSale:
            _findIndex(
              action.data.permissions,
              p => p.action === 'review_sale_return_details'
            ) >= 0,
          canApproveReturnSale:
            _findIndex(
              action.data.permissions,
              p => p.action === 'approve_sale_return_details'
            ) >= 0,
          canRevokeReturnSale:
            _findIndex(
              action.data.permissions,
              p => p.action === 'revoke_sale_return_details'
            ) >= 0,
          canReviewQuotaTransfer:
            _findIndex(
              action.data.permissions,
              p => p.action === 'review_quota_transfer'
            ) >= 0,
          canApproveQuotaTransfer:
            _findIndex(
              action.data.permissions,
              p => p.action === 'approve_quota_transfer'
            ) >= 0,
          canRevokeQuotaTransfer:
            _findIndex(
              action.data.permissions,
              p => p.action === 'revoke_quota_transfer'
            ) >= 0,
          canUpdatePOS:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_point_of_sales'
            ) >= 0,
          canGenPromissoryPDF:
            _findIndex(
              action.data.permissions,
              p => p.action === 'generate_promissory_pdf'
            ) >= 0,
          canGenInvoicePDF:
            _findIndex(
              action.data.permissions,
              p => p.action === 'generate_invoice_pdf'
            ) >= 0,
          canGenAmortizationPDF:
            _findIndex(
              action.data.permissions,
              p => p.action === 'generate_amortization_pdf'
            ) >= 0,
          canGenVoucherPDF:
            _findIndex(
              action.data.permissions,
              p => p.action === 'generate_voucher_pdf'
            ) >= 0,
          canGenSecurePDF:
            _findIndex(
              action.data.permissions,
              p => p.action === 'generate_secure_pdf'
            ) >= 0,
          canGenEnsuranceRequestPDF:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_ensurance_request_pdf'
            ) >= 0,
          canCreateManualQuota:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_manual_quota'
            ) >= 0,
          canListManualQuota:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_manual_quota'
            ) >= 0,
          canReviewManualQuota:
            _findIndex(
              action.data.permissions,
              p => p.action === 'review_manual_quota'
            ) >= 0,
          canApproveManualQuota:
            _findIndex(
              action.data.permissions,
              p => p.action === 'approve_manual_quota'
            ) >= 0,
          canRevokeManualQuota:
            _findIndex(
              action.data.permissions,
              p => p.action === 'revoke_manual_quota'
            ) >= 0,
          canDeactivateManualQuota:
            _findIndex(
              action.data.permissions,
              p => p.action === 'deactivate_manual_quota'
            ) >= 0,
          canAddCommentsToPrommissory:
            _findIndex(
              action.data.permissions,
              p => p.action === 'add_comments_to_promissories'
            ) >= 0,
          canListDashboardSummaries:
            _findIndex(
              action.data.permissions,
              p => p.action === 'dashboard_summaries'
            ) >= 0,
          canListDashboardSales:
            _findIndex(
              action.data.permissions,
              p => p.action === 'dashboard_sales'
            ) >= 0,
          canListDashboardTopRated:
            _findIndex(
              action.data.permissions,
              p => p.action === 'dashboard_top_rated'
            ) >= 0,
          canListDashboardSellersSummaries:
            _findIndex(
              action.data.permissions,
              p => p.action === 'dashboard_sellers_summaries'
            ) >= 0,
          canListDashboardSellersSales:
            _findIndex(
              action.data.permissions,
              p => p.action === 'dashboard_sellers_sales'
            ) >= 0,
          canListDashboardTopRanking:
            _findIndex(
              action.data.permissions,
              p => p.action === 'dashboard_top_ranking'
            ) >= 0,
          canListDataSalesman:
            _findIndex(
              action.data.permissions,
              p => p.action === 'dashboard_data_salesman'
            ) >= 0,
          canListDataSupplier:
            _findIndex(
              action.data.permissions,
              p => p.action === 'dashboard_data_supplier'
            ) >= 0,
          canViewSummary:
            _findIndex(
              action.data.permissions,
              p => p.action === 'summary_freelance'
            ) >= 0,
          canViewDashboardCounters:
            _findIndex(
              action.data.permissions,
              p => p.action === 'dashboard_counters'
            ) >= 0,
          canFilterDashboardBySupplier:
            _findIndex(
              action.data.permissions,
              p => p.action === 'filter_dashboard_by_supplier'
            ) >= 0,
          canFilterDashboardByZone:
            _findIndex(
              action.data.permissions,
              p => p.action === 'filter_dashboard_by_zone'
            ) >= 0,
          canFilterDashboardByPos:
            _findIndex(
              action.data.permissions,
              p => p.action === 'filter_dashboard_by_pos'
            ) >= 0,
          canFilterDashboardByChannel:
            _findIndex(
              action.data.permissions,
              p => p.action === 'filter_dashboard_by_channel'
            ) >= 0,
          canFilterDashboardByMonth:
            _findIndex(
              action.data.permissions,
              p => p.action === 'filter_dashboard_by_month'
            ) >= 0,
          canFilterDashboardByYear:
            _findIndex(
              action.data.permissions,
              p => p.action === 'filter_dashboard_by_year'
            ) >= 0,

          canFilterDashboardByType:
            _findIndex(
              action.data.permissions,
              p => p.action === 'filter_dashboard_by_type'
            ) >= 0,
          canUpdateGlobalSettings:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_global_settings'
            ) >= 0,
          canUpdateCategories:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_categories'
            ) >= 0,
          canCreateCategories:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_categories'
            ) >= 0,
          canUpdateCommissions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_commissions'
            ) >= 0,
          canCreateCommissions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_commissions'
            ) >= 0,
          canCreateExtraQuota:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_extra_quota'
            ) >= 0,
          canUpdateExtraQuota:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_extra_quota'
            ) >= 0,
          canViewSales:
            _findIndex(
              action.data.permissions,
              p => p.action === 'view_sales'
            ) >= 0,
          canViewPromissories:
            _findIndex(
              action.data.permissions,
              p => p.action === 'view_promissories'
            ) >= 0,
          canReattachVoucherOnRemoteSale:
            _findIndex(
              action.data.permissions,
              p => p.action === 'reattach_voucher_to_remote_sale'
            ) >= 0,
          canViewInvoiceQuestions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'view_invoice_questions'
            ) >= 0,
          canCheckInvoiceAnswers:
            _findIndex(
              action.data.permissions,
              p => p.action === 'check_invoice_validation'
            ) >= 0,
          canCreateFinancingPlanToCategories:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_financing_plans_to_categories'
            ) >= 0,
          canUpdateFinancingPlanToCategories:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_financing_plans_to_categories'
            ) >= 0,
          canListHomeVisits:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_schedules'
            ) >= 0,
          canUpdateHomeVisits:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_schedules'
            ) >= 0,
          canLegalizeHomeVisits:
            _findIndex(
              action.data.permissions,
              p => p.action === 'legalize_schedules'
            ) >= 0,
          canShowHomeVisit:
            _findIndex(
              action.data.permissions,
              p => p.action === 'view_schedules'
            ) >= 0,
          canApproveSeller:
            _findIndex(
              action.data.permissions,
              p => p.action === 'approve_point_user_association'
            ) >= 0,
          canRevokeSeller:
            _findIndex(
              action.data.permissions,
              p => p.action === 'revoke_point_user_association'
            ) >= 0,
          canAddCoordinator:
            _findIndex(
              action.data.permissions,
              p => p.action === 'relate_point_of_sales_and_users'
            ) >= 0,
          canCreatePromissoryRanges:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_promissory_ranges'
            ) >= 0,
          canCreatePromissories:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_promissories'
            ) >= 0,
          canUpdatePromissoryRanges:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_promissory_ranges'
            ) >= 0,
          canCreateArticles:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_articles'
            ) >= 0,
          canUpdateArticles:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_articles'
            ) >= 0,
          canExportSalesToExcel:
            _findIndex(
              action.data.permissions,
              p => p.action === 'export_sales'
            ) >= 0,
          canExportSchedulesToExcel:
            _findIndex(
              action.data.permissions,
              p => p.action === 'export_schedules_to_excel'
            ) >= 0,
          canExportArticlesToExcel:
            _findIndex(
              action.data.permissions,
              p => p.action === 'export_articles_to_excel'
            ) >= 0,
          canRelatePOSToSeller:
            _findIndex(
              action.data.permissions,
              p => p.action === 'relate_point_of_sale_to_seller'
            ) >= 0,
          canListSchedulePointOfSaleConfigurations:
            _findIndex(
              action.data.permissions,
              p =>
                p.action === 'list_schedule_point_of_sale_configurations'
            ) >= 0,
          canCreateSchedulePointOfSaleConfigurations:
            _findIndex(
              action.data.permissions,
              p =>
                p.action ===
                'create_schedule_point_of_sale_configurations'
            ) >= 0,
          canUpdateSchedulePointOfSaleConfigurations:
            _findIndex(
              action.data.permissions,
              p =>
                p.action ===
                'update_schedule_point_of_sale_configurations'
            ) >= 0,
          canListUserNames:
            _findIndex(
              action.data.permissions,
              p => p.action === 'filter_list_users'
            ) >= 0,
          canListScoringExclusions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_scoring_exclusions'
            ) >= 0,
          canCreateScoringExclusions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_scoring_exclusions'
            ) >= 0,
          canUpdateScoringExclusions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_scoring_exclusions'
            ) >= 0,
          canDestroyScoringExclusions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'destroy_scoring_exclusions'
            ) >= 0,
          canListLocationExceptions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_location_exceptions'
            ) >= 0,
          canCreateLocationExceptions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_location_exceptions'
            ) >= 0,
          canUpdateLocationExceptions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_location_exceptions'
            ) >= 0,
          canDestroyLocationExceptions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'destroy_location_exceptions'
            ) >= 0,
          canUpdateUserIdentification:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_user_identification'
            ) >= 0,
          canUpdateSupplierGeographicLocations:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_suppliers_geographic_locations'
            ) >= 0,
          canEditBlackList:
            _findIndex(
              action.data.permissions,
              p => p.action === 'edit_blacklist'
            ) >= 0,
          canVisualizeBlackList:
            _findIndex(
              action.data.permissions,
              p => p.action === 'visualize_blacklist'
            ) >= 0,
          canListAssignedQuotas:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_assigned_quotas'
            ) >= 0,
          canUpdateAssignedQuotas:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_assigned_quota'
            ) >= 0,
          canCreateAssignedQuotas:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_assigned_quotas'
            ) >= 0,
          canDeleteAssignedQuotas:
            _findIndex(
              action.data.permissions,
              p => p.action === 'delete_assigned_quota'
            ) >= 0,
          canListSubsegments:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_subsegments'
            ) >= 0,
          canUpdateSubsegments:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_subsegments'
            ) >= 0,
          canListServices:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_services'
            ) >= 0,
          canCreateServices:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_services'
            ) >= 0,
          canModifyServices:
            _findIndex(
              action.data.permissions,
              p => p.action === 'modify_services'
            ) >= 0,
          canListScoringResponses:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_scoring_responses'
            ) >= 0,
          canConsultScoringOnSale:
            _findIndex(
              action.data.permissions,
              p => p.action === 'consult_scoring_on_sale'
            ) >= 0,
          canCreateSchedule:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_schedules'
            ) >= 0,
          canListCampaign:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_campaign'
            ) >= 0,
          canCreateCampaign:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_campaign'
            ) >= 0,
          canUpdateCampaign:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_campaign'
            ) >= 0,
          canDestroyCampaign:
            _findIndex(
              action.data.permissions,
              p => p.action === 'destroy_campaign'
            ) >= 0,
          canValidateCampaign:
            _findIndex(
              action.data.permissions,
              p => p.action === 'validate_campaign'
            ) >= 0,
          canCreateSupplierProposals:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_supplier_proposals'
            ) >= 0,
          canListSupplierProposals:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_supplier_proposals'
            ) >= 0,
          canViewSupplierProposals:
            _findIndex(
              action.data.permissions,
              p => p.action === 'view_supplier_proposals'
            ) >= 0,
          canUpdateCategoriesNameInSupplierProposalModuleField:
            _findIndex(
              action.data.permissions,
              p =>
                p.action ===
                'update_categories_name_in_supplier_proposal_module_field'
            ) >= 0,
          canCreateSupplierProposalDocuments:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_supplier_proposal_documents'
            ) >= 0,
          canListLineExecutiveProfiles:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_line_executive_profiles'
            ) >= 0,
          canCreateLineExecutiveProfiles:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_line_executive_profiles'
            ) >= 0,
          canSetMaxItemsPerArticle:
            _findIndex(
              action.data.permissions,
              p => p.action === 'set_max_items_per_article'
            ) >= 0,
          canSetMaxItemsPerCategory:
            _findIndex(
              action.data.permissions,
              p => p.action === 'set_max_items_per_category'
            ) >= 0,
          canListBrand:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_brands'
            ) >= 0,
          canCreateBrands:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_brands'
            ) >= 0,
          canUpdateBrands:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_brands'
            ) >= 0,
          canListPandotyCoursesInMyProfile:
            _findIndex(
              action.data.permissions,
              p => p.action === 'show_pandoty_courses_my_profile'
            ) >= 0,
          canSetPortalELearningButtomInNavProfile:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_pandoty_credentials'
            ) >= 0,
          canVerifyPhoneCode:
            _findIndex(
              action.data.permissions,
              p => p.action === 'verify_phone_code'
            ) >= 0,
          canListSecureCreditInstalmentsPercentages:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_secure_credit_instalments_percentages'
            ) >= 0,
          canCreateSecureCreditInstalmentsPercentages:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_secure_credit_instalments_percentages'
            ) >= 0,
          canUpdateSecureCreditInstalmentsPercentages:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_secure_credit_instalments_percentages'
            ) >= 0,
          canDeleteSecureCreditInstalmentsPercentages:
            _findIndex(
              action.data.permissions,
              p => p.action === 'delete_secure_credit_instalments_percentages'
            ) >= 0,
          canValidateNewQuotaTransferOnSale:
            _findIndex(
              action.data.permissions,
              p => p.action === 'new_quota_transfer'
            ) >= 0,
          canRevokeArticlesSales:
            _findIndex(
              action.data.permissions,
              p => p.action === 'revoke_articles_sales'
            ) >= 0,
          canRevokeSales:
            _findIndex(
              action.data.permissions,
              p => p.action === 'revoke_sales'
            ) >= 0,
          canReturnArticlesSalesMassive:
            _findIndex(
              action.data.permissions,
              p => p.action === 'return_articles_sales_massive'
            ) >= 0,
          canCreateMassiveManualQuota:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_massive_manual_quota'
            ) >= 0,
          CanShowHealthView:
            _findIndex(
              action.data.permissions,
              p => p.action === 'show_health_view'
            ) >= 0,
          canRegisterSinisters:
            _findIndex(
              action.data.permissions,
              p => p.action === 'register_sinisters'
            ) >= 0,
          canListSinisters:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_sinisters'
            ) >= 0,
          canApproveSinister:
            _findIndex(
              action.data.permissions,
              p => p.action === 'approve_sinister'
            ) >= 0,
          canRevokeSinister:
            _findIndex(
              action.data.permissions,
              p => p.action === 'revoke_sinister'
            ) >= 0,
          canUpdateSecureProduct:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_secure_product'
            ) >= 0,
          canCheckSecureSaleScoreExperian:
            _findIndex(
              action.data.permissions,
              p => p.action === 'check_secure_sale_score_experian'
            ) >= 0,
          canSendWhatsappOtpValidationMessage:
            _findIndex(
              action.data.permissions,
              p => p.action === 'send_whatsapp_otp_validation_message'
            ) >= 0,
          canCheckVoluntaryInsuranceLayerTwoBuyability:
            _findIndex(
              action.data.permissions,
              p => p.action === 'check_voluntary_insurance_layer_two_buyability'
            ) >= 0,
          canAttachDocumentsOnTraditionalSale:
            _findIndex(
              action.data.permissions,
              p => p.action === 'attach_documents_on_traditional_sale'
            ) >= 0,
          canCheckMegaQuotaInfo:
            _findIndex(
              action.data.permissions,
              p => p.action === 'check_mega_quota_info'
            ) >= 0,
          canUpdatePOSLocationInfo:
            _findIndex(
              action.data.permissions,
              p => p.action === 'update_point_of_sale_location_info'
            ) >= 0,
          canExportCategories:
            _findIndex(
              action.data.permissions,
              p => p.action === 'export_categories'
            ) >= 0,
          canInitiateOlimpiaV2Process:
            _findIndex(
              action.data.permissions,
              p => p.action === 'initiate_olimpia_v2_process'
            ) >= 0,
          canCreateMassivePeriodGrace:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_massive_period_grace'
            ) >= 0,
          canCreateMassivePeriodGrace:
            _findIndex(
              action.data.permissions,
              p => p.action === 'create_massive_period_grace'
            ) >= 0,
          canSendFacialBiometricMessage:
            _findIndex(
              action.data.permissions,
              p => p.action === 'send_facial_biometric_message'
            ) >= 0,
          canCheckFacialBiometricStatus:
            _findIndex(
              action.data.permissions,
              p => p.action === 'check_facial_biometric_status'
            ) >= 0,
          canListDocRevisions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'list_doc_revisions'
            ) >= 0,
          canApproveDocRevisions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'approve_doc_revisions'
            ) >= 0,
          canRevokeDocRevisions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'revoke_doc_revisions'
            ) >= 0,
          canAttachDocumentsForDocRevisions:
            _findIndex(
              action.data.permissions,
              p => p.action === 'attach_documents_for_doc_revisions'
            ) >= 0,
          canGeneratePledgePDF:
            _findIndex(
              action.data.permissions,
              p => p.action === 'generate_pledge_pdf'
            ) >= 0,
          canPreapproveSale:
            _findIndex(
              action.data.permissions,
              p => p.action === 'pre_approve_sale'
            ) >= 0,
        },
        permissions: action.permissions
      };
    case SET_COURSE_DETAIL:
      return {
        ...state,
        courseDetail: action.data,
      };
    default:
      return state;
  }
}
